<template>
  <el-dialog
    v-model="showExplainDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-reconciliation-explain-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-reconciliation-explain-dialog-header__title">
        {{ transformI18n(dialogTitle) }}
      </h4>
    </template>

    <div v-loading="loading" style="width: 100%">
      <ExplanationReasonForm
        v-if="showExplainDialog"
        ref="explanationReasonFormRef"
        v-model="explanationReasonForm"
        :model="props.model"
        :adjustmentJournalTitle="
          props.model === 'add'
            ? $t('reconciliation.createReconciliationAdjustmentJournal')
            : $t('reconciliation.reconciliationAdjustmentJournal')
        "
        :disableSave="props?.currentData?.status === 'ADJUSTMENT'"
        :dataType="props.dataType"
        :currentData="explainDetail"
      />
    </div>

    <template v-if="props.model === 'add' || props?.currentData?.status !== 'ADJUSTMENT'" #footer>
      <elv-button
        height="44"
        width="100"
        round
        type="primary"
        :loading="submitLoading"
        :disabled="loading"
        @click="onSaveExplain"
      >
        {{ t('button.save') }}</elv-button
      >
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'
import { $t, transformI18n } from '@/i18n/index'
import { cloneDeep, find, isEmpty } from 'lodash-es'
import ReconciliationApi from '@/api/ReconciliationApi'
import { ReconciliationExplainType } from '#/ReconciliationTypes'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import ExplanationReasonForm from '../../components/ExplanationReasonForm.vue'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  dataType: {
    type: String, // TRANSACTION | LEDGER
    required: true
  },
  reconciliationDataIds: {
    type: Array<string>,
    default: () => {
      return []
    }
  },
  side: {
    type: String,
    default: 'A'
  },
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const { t } = useI18n()
const route = useRoute()

const reconciliationStore = useReconciliationStore()
const emit = defineEmits(['onResetList'])
const explanationReasonFormRef = useTemplateRef('explanationReasonFormRef')

const loading = ref(false)
const submitLoading = ref(false)
const showExplainDialog = ref(false)
const explainDetail = ref<ReconciliationExplainType>({} as ReconciliationExplainType)
const explanationReasonForm = ref({
  reconciliationReasonId: '',
  name: '',
  description: '',
  chartOfAccountId: '',
  auxiliaryValueList: [],
  memo: '',
  journalAction: 'CREATE'
})

const reconciliationTaskId = computed(() => {
  return route.params?.reconciliationTaskId as string
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const contentPaddingBottom = computed(() => {
  return props?.currentData?.status === 'ADJUSTMENT' ? '20px' : '6px'
})

const dialogTitle = computed(() => {
  switch (props.dataType) {
    case 'TRANSACTION':
      return $t('title.explainUnmatchedTransfer')
    case 'LEDGER':
      return $t('title.explainUnmatchedLedger')
    default:
      break
  }
  return ''
})

// 切换弹窗展示
const onCheckExplainDialog = () => {
  showExplainDialog.value = !showExplainDialog.value
}

/**
 * @description: 添加解释
 */
const onCreateExplain = async () => {
  return new Promise((resolve, reject) => {
    const data: any = {
      reconciliationDataIds: props.reconciliationDataIds,
      side: props.side,
      ...cloneDeep(explanationReasonForm.value)
    }
    delete data.journalAction
    const reason = find(reconciliationStore.reconciliationTaskReasonList, {
      reconciliationReasonId: data.reconciliationReasonId
    })
    if (isEmpty(reason)) {
      data.name = data.reconciliationReasonId
      delete data.reconciliationReasonId
    } else {
      delete data.name
    }
    if (props.dataType === 'TRANSACTION') {
      delete data.auxiliaryValueList
      delete data.chartOfAccountId
      delete data.memo
    }
    ReconciliationApi.addReconciliationTaskReason(entityId.value, reconciliationTaskId.value, data)
      .then((res) => {
        resolve(res)
      })
      .catch((error: any) => {
        ElMessage.error(error.message)
        reject()
      })
  })
}

/**
 * @description: 编辑解释
 */
const onEditExplain = () => {
  return new Promise((resolve, reject) => {
    const data: any = {
      reconciliationDataId: props.currentData?.reconciliationDataId,
      side: props.side,
      ...cloneDeep(explanationReasonForm.value)
    }
    const reason = find(reconciliationStore.reconciliationTaskReasonList, {
      reconciliationReasonId: data.reconciliationReasonId
    })
    if (isEmpty(reason)) {
      data.name = data.reconciliationReasonId
      delete data.reconciliationReasonId
    } else {
      delete data.name
    }
    if (['KEEP', 'DELETE'].includes(data.journalAction)) {
      delete data.auxiliaryValueList
      delete data.chartOfAccountId
      delete data.memo
    }
    ReconciliationApi.updateReconciliationTaskReason(entityId.value, reconciliationTaskId.value, data)
      .then((res) => {
        resolve(res)
      })
      .catch((error: any) => {
        ElMessage.error(error.message)
        reject()
      })
  })
}

/**
 * @description: 保存解释
 */
const onSaveExplain = async () => {
  explanationReasonFormRef.value?.explanationReasonFormRef?.validate(async (valid: boolean) => {
    if (valid) {
      try {
        submitLoading.value = true
        if (props.model === 'add') {
          await onCreateExplain()
        } else {
          await onEditExplain()
        }
        ElMessage.success(t('message.success'))
        emit('onResetList')
        onCheckExplainDialog()
      } catch (error: any) {
        console.log(error)
        ElMessage.error(error.message)
      } finally {
        submitLoading.value = false
      }
    }
  })
}

const onCloseDialog = () => {
  explanationReasonForm.value = {
    reconciliationReasonId: '',
    name: '',
    description: '',
    chartOfAccountId: '',
    auxiliaryValueList: [],
    memo: '',
    journalAction: 'CREATE'
  }
  explainDetail.value = {} as ReconciliationExplainType
}

const fetchReconciliationTaskReasonDetail = async () => {
  try {
    loading.value = true
    const params = {
      reconciliationDataId: props.currentData?.reconciliationDataId,
      side: props.side
    }
    const { data } = await ReconciliationApi.getReconciliationTaskReasonDetail(
      entityId.value,
      reconciliationTaskId.value,
      params
    )
    explainDetail.value = data
  } catch (error: any) {
    console.error(error)
    ElMessage.error(error.message)
  } finally {
    loading.value = false
  }
}

defineExpose({ onCheckExplainDialog })

watch(
  [() => props.currentData, () => showExplainDialog.value],
  () => {
    if (showExplainDialog.value && props.model === 'edit' && props.currentData?.reconciliationDataId) {
      fetchReconciliationTaskReasonDetail()
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-reconciliation-explain-dialog {
  width: 620px;
  min-height: 278px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-reconciliation-explain-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    padding: 18px 24px v-bind('contentPaddingBottom');
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
      margin: 0;
    }

    .elv-apply-project-form {
      margin-top: 0px;
      padding-bottom: 0px;
    }

    .el-input {
      width: 572px;
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input__wrapper {
      border-radius: 4px;
      border: 1px solid #dde1e6;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
      padding: 1px 12px;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }

      .el-form-item__label {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-transform: capitalize;
        color: #636b75;
      }
    }

    .el-select {
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }

    .elv-source-dialog-exchange-prefix {
      width: 20px;
      height: 20px;
      display: block;
    }
  }

  .el-dialog__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
}

.elv-source-dialog-popper {
  &.el-popper {
    transform: translateY(-11.5px);
  }

  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    &.hover {
      background: #f9fafb;
    }

    .elv-source-dialog-exchange-option {
      display: flex;
      align-items: center;

      img {
        display: block;
        width: 20px;
        height: 20px;
        filter: drop-shadow(0px 1px 3px rgba(33, 27, 78, 0.15));
        margin-right: 10px;
      }

      p {
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #0e0f11;
        display: flex;
        align-items: center;
      }

      span {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 4px;
        height: 18px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        margin-left: 10px;
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        color: #d0d4d9;
      }
    }
  }
}
</style>
