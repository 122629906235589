<template>
  <div class="elv-journals-table-cell">
    <div class="elv-journals-table-cell__balance">
      {{
        props.params?.data?.credit !== null &&
        !Big(props.params?.data?.credit ?? '0').eq(0) &&
        props.params?.data?.credit !== 0
          ? 'Cr'
          : 'Dr'
      }}
    </div>
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'

const props = defineProps({
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
</script>

<style lang="scss" scoped>
.elv-journals-table-cell {
  .elv-journals-table-cell__balance {
    color: #0e0f11;
    font-family: 'Plus Jakarta Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}
</style>
