<template>
  <div class="elv-reconciliation-unmatched-table-wrapper">
    <el-table
      ref="tableListRef"
      v-loading="props.tableLoading"
      :data="props.tableData.list"
      border
      stripe
      width="100%"
      row-key="transactionId"
      height="100%"
      class="elv-reconciliation-unmatched-table"
      header-cell-class-name="elv-reconciliation-unmatched-table-header__cell"
      header-row-class-name="elv-reconciliation-unmatched-table-header"
      row-class-name="elv-reconciliation-unmatched-table-row"
      cell-class-name="elv-reconciliation-unmatched-table-row__cell"
      @selection-change="onSelectionChange"
      @row-click="onCellClicked"
    >
      <el-table-column
        type="selection"
        width="40"
        :selectable="selectable"
        fixed="left"
        reserve-selection
        label-class-name="elv-reconciliation-unmatched-table-row__index is-last-column"
      />
      <el-table-column min-width="160" :label="t('common.type')">
        <template #default="{ row }">
          <div class="elv-reconciliation-unmatched-table-row__cell-type">
            <SvgIcon :name="typeData(row).value?.icon" width="14" height="14" />
            <span> {{ transformI18n(platformType(row.transactionPlatformType)) }}</span>
            <span>&nbsp;{{ transformI18n(typeData(row).value?.text) }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column min-width="203" :label="t('common.dateTime')">
        <template #default="{ row }">
          <DateTimeCell :params="{ data: { ...row, txHash: row?.transactionTxHash || '' } }" />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('report.Base Asset')"
        width="110"
        class-name="elv-reconciliation-unmatched-table-row__currency"
        label-class-name="elv-reconciliation-unmatched-table-row__currency"
      >
        <template #default="{ row }">
          <CurrencyCell :params="{ data: row, value: { cellName: 'Currency' } }" type="transactions" />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('report.Amount')"
        min-width="120"
        label-class-name="elv-reconciliation-unmatched-table-row__amount el-table-fixed-column--right is-first-column"
        align="right"
        class-name="elv-reconciliation-unmatched-table-row__amount"
        header-align="right"
        fixed="right"
      >
        <template #default="{ row }">
          <TextCell
            :text="formatNumber(row.amount ? new Big(row.amount).abs().toString() : 0, 20)"
            align="right"
            :weight="500"
            :title-desc="formatNumber(row.amount ? new Big(row.amount).abs().toString() : 0, 20)"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="props.activeTab === 'PROCESSED'"
        :label="t('common.status')"
        min-width="100"
        label-class-name="elv-reconciliation-unmatched-table-row__amount el-table-fixed-column--right"
        align="right"
        class-name="elv-reconciliation-unmatched-table-row__status"
        header-align="right"
        fixed="right"
      >
        <template #default="{ row }">
          <template v-if="row.reconciliationMatch?.matchNo">
            <div class="is-matched" @click="emit('onViewMatchDetail', row)">{{ t('report.matched') }}</div>
            <p>{{ row.reconciliationMatch?.matchNo }}</p>
          </template>
          <template v-else-if="row.reconciliationReason?.reconciliationReasonId">
            <div class="is-explained" @click="emit('onEditExplain', row)">{{ t('reconciliation.explained') }}</div>
            <p>{{ row.reconciliationReason?.name }}</p>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      class="elv-transactions-list-pagination"
      size-popper-class="elv-transactions-pagination__sizes-popper"
      :limit="props.params.limit"
      :current-page="props.params.page"
      :total="props.tableData.filteredCount"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />
  </div>

  <TransferDetailOverlay
    ref="transactionEditOverlayRef"
    :isBatch="false"
    :currentData="overlayDrawerData.currentData"
    @onResetList="emit('onResetList')"
  />

  <CreateJournalDialog
    ref="createJournalDialogRef"
    :journalTypeList="journalTypeFilterList"
    :isBatch="true"
    :multipleSelection="props.tableDataSelected"
    :currentData="{}"
    model="add"
    @onResetList="onResetSelectList"
  />

  <TransferFilterOverlay
    ref="transferFilterOverlayRef"
    tableType="RECONCILIATION"
    :filterType="`RECONCILIATION_${props.activeTab}_${props.side}`"
    @onFilterData="emit('onChangePage', 1)"
  />
</template>

<script setup lang="ts">
import Big from 'big.js'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import { transactionType } from '@/config/index'
import { $t, transformI18n } from '@/i18n/index'
import { map, flatMap, isEmpty } from 'lodash-es'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import DateTimeCell from '@/components/Reports/Cell/DateTimeCell.vue'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import TransferFilterOverlay from '../../../../components/Transfer/FilterOverlay.vue'
import TransferDetailOverlay from '../../../../components/Transfer/DetailOverlay.vue'
import CreateJournalDialog from '../../../../components/Transfer/CreateJournalDialog.vue'
import { DetailsReconciliationTransactionType, ReconciliationMatchedTransactionItemType } from '#/ReconciliationTypes'

const props = defineProps({
  tableData: {
    type: Object as () => DetailsReconciliationTransactionType,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  params: {
    type: Object,
    required: true
  },
  tableDataSelected: {
    type: Array as () => ReconciliationMatchedTransactionItemType[],
    required: true
  },
  activeTab: {
    type: String,
    required: true
  },
  selectedTotalAmount: {
    type: Number,
    required: true
  },
  disabledSelected: {
    type: String,
    default: ''
  },
  side: {
    type: String,
    required: true
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
const transactionStore = useTransactionStore()

const emit = defineEmits([
  'onClearSelected',
  'onChangePage',
  'onResetList',
  'onEditExplain',
  'onViewMatchDetail',
  'onChangePageSize',
  'onSelectionChange'
])

const tableListRef = useTemplateRef('tableListRef')
const createJournalDialogRef = useTemplateRef('createJournalDialogRef')
const transferFilterOverlayRef = useTemplateRef('transferFilterOverlayRef')
const overlayDrawerData: any = reactive({
  currentData: {},
  isBatch: false
})

const transactionEditOverlayRef = ref()

const journalTypeFilterList = computed(() => {
  let direction = 'filter'
  if (
    !isEmpty(props.tableDataSelected) &&
    map(props.tableDataSelected, 'direction').every((val, i, arr) => val === arr[0])
  ) {
    direction = props.tableDataSelected[0]?.direction
  }
  return transactionStore.journalTypeFilterList(direction)
})

const typeData: any = useComputedHook((row: any) => {
  let data: any = {}
  const transactionTypeList = flatMap(transactionType, 'list')
  transactionTypeList.forEach((item) => {
    if (item.value === row.transactionType) {
      data = {
        icon: item.icon,
        text: item.label
      }
    }
  })
  return data
})

const platformType = computed(() => {
  return (type: string) => {
    let text = ''
    switch (type) {
      case 'CHAIN':
        text = $t('common.onChain')
        break
      case 'EXCHANGE':
        text = $t('common.exchange')
        break
      case 'BANK':
        text = $t('common.bank')
        break
      case 'CUSTODY':
        text = $t('common.custody')
        break
      case 'PAYMENT':
        text = $t('common.payment')
        break
      default:
        text = ''
        break
    }
    return text
  }
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const selectable = (row: any) => {
  if (props.disabledSelected === '') return true
  if (props.activeTab === 'PENDING') {
    return row.underlyingCurrency.symbol === props.disabledSelected
  }
  if (props.disabledSelected === 'MATCHED') {
    return !!row.reconciliationMatch?.matchNo
  }
  if (props.disabledSelected === 'EXPLAINED') {
    return !!row.reconciliationReason?.reconciliationReasonId
  }
  return true
}

// eslint-disable-next-line no-unused-vars
const onCellClicked = async (row: any, column: any, e: any) => {
  try {
    console.log(row)
    overlayDrawerData.currentData = {}
    if (
      column?.no !== 0 &&
      column?.no !== 5 &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__copy' &&
      e.target?.className !== 'elv-financials-cell-date-time-main-bottom__txHash'
    ) {
      if (
        ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
        !currentEntityPermission.value?.transaction?.viewDetail
      ) {
        ElMessage.warning(t('message.noPermission'))
        return
      }
      overlayDrawerData.currentData = row
      transactionEditOverlayRef.value?.onCheckDrawerStatus()
    }
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

// eslint-disable-next-line no-unused-vars
const onUpdateAccountOrContact = (data: any) => {
  overlayDrawerData.currentData = data
}

const onSelectionChange = (val: any) => {
  emit('onSelectionChange', val)
}

const onResetSelectList = () => {
  emit('onResetList')
  emit('onClearSelected')
}

defineExpose({ tableListRef, createJournalDialogRef, transferFilterOverlayRef })
</script>

<style lang="scss" scoped>
.elv-reconciliation-unmatched-table-wrapper {
  width: 100%;
  height: calc(100% - 50px);
  position: relative;
}
</style>
