<template>
  <div class="elv-reconciliation-unmatched-table-wrapper">
    <el-table
      ref="tableListRef"
      v-loading="props.tableLoading"
      :data="props.tableData?.list"
      border
      stripe
      width="100%"
      height="100%"
      row-key="generalLedgerId"
      class="elv-reconciliation-unmatched-table"
      header-cell-class-name="elv-reconciliation-unmatched-table-header__cell"
      header-row-class-name="elv-reconciliation-unmatched-table-header"
      row-class-name="elv-reconciliation-unmatched-table-row"
      :cell-class-name="setCellClassName"
      @row-click="onCellClicked"
      @selection-change="onSelectionChange"
    >
      <el-table-column
        type="selection"
        width="40"
        fixed="left"
        label-class-name="elv-reconciliation-unmatched-table-row__index is-last-column"
        :selectable="selectable"
        reserve-selection
      />

      <el-table-column width="100" :label="t('common.date')">
        <template #default="{ row }">
          <DateJournalNo :params="{ data: row?.ledger }" />
        </template>
      </el-table-column>
      <el-table-column :label="t('common.account')" min-width="150">
        <template #default="{ row }">
          <AccountCell :params="{ data: row }" />
          <journalTypeCell :params="{ data: row }" />
        </template>
      </el-table-column>
      <el-table-column :label="t('report.Dr/Cr')" width="54">
        <template #default="{ row }">
          <BalanceCell :params="{ data: row?.ledger ?? {} }" />
        </template>
      </el-table-column>

      <el-table-column :label="t('report.auxiliaryCode')" min-width="110">
        <template #default="{ row }">
          <AuxiliaryCode :params="{ data: row?.ledger ?? {} }" />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('report.Currency')"
        min-width="100"
        class-name="elv-reconciliation-unmatched-table-row__currency"
        label-class-name="elv-reconciliation-unmatched-table-row__currency"
      >
        <template #default="{ row }">
          <CurrencyCell :params="{ data: row, value: { cellName: 'Currency' } }" />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('report.Amount')"
        min-width="100"
        align="right"
        header-align="right"
        fixed="right"
        class-name="elv-reconciliation-unmatched-table-row__amount"
        label-class-name="elv-reconciliation-unmatched-table-row__amount el-table-fixed-column--right is-first-column"
      >
        <template #default="{ row }">
          <TextCell
            :text="
              formatNumber(
                row.amount !== null && row.amount !== '0' ? new Big(row.amount).abs().toString() : row.amount,
                20
              )
            "
            :title-desc="
              formatNumber(
                row.amount !== null && row.amount !== '0' ? new Big(row.amount).abs().toString() : row.amount,
                20
              )
            "
            align="right"
            :weight="500"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="props.activeTab === 'PROCESSED'"
        :label="t('common.status')"
        min-width="100"
        align="right"
        header-align="right"
        fixed="right"
        class-name="elv-reconciliation-unmatched-table-row__status"
        label-class-name="elv-reconciliation-unmatched-table-row__amount el-table-fixed-column--right"
      >
        <template #default="{ row }">
          <template v-if="row.reconciliationMatch?.matchNo && row?.status !== 'ADJUSTMENT'">
            <div class="is-matched" @click="emit('onViewMatchDetail', row)">{{ t('report.matched') }}</div>
            <p>{{ row.reconciliationMatch?.matchNo }}</p>
          </template>
          <template v-else-if="row.reconciliationReason?.reconciliationReasonId">
            <div class="is-explained" @click="emit('onEditExplain', row)">{{ t('reconciliation.explained') }}</div>
            <p><span v-if="row?.status === 'ADJUSTMENT'">Recon-</span>{{ row.reconciliationReason?.name }}</p>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <elv-pagination
      class="elv-journals-list-pagination"
      size-popper-class="elv-journals-pagination__sizes-popper"
      :limit="props.params.limit"
      :current-page="props.params.page"
      :total="props.tableData.filteredCount"
      @current-change="onChangePage"
      @size-change="onChangePageSize"
    />

    <DetailOverlay
      ref="detailOverlayRef"
      :currentData="currentData"
      @onResetList="onChangePage(1)"
      @onEditJournal="onEditJournal"
    />

    <AddJournalDialog
      ref="addJournalDialogRef"
      model="edit"
      tableType="journal"
      :auxiliaryAccountTypeId="auxiliaryAccountTypeId"
      :currentData="currentData"
      :journalTypeList="journalTypeFilterList"
      @resetDetail="detailOverlayRef?.fetchJournalDetail()"
      @resetList="onChangePage(1)"
    />

    <JournalFilterOverlay
      ref="JournalFilterOverlayRef"
      :filterType="`RECONCILIATION_${props.activeTab}_${props.side}`"
      @onFilterData="onChangePage(1)"
    />
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
import { formatNumber } from '@/lib/utils'
import AccountCell from '../Cell/AccountCell.vue'
import BalanceCell from '../Cell/BalanceCell.vue'
import DateJournalNo from '../Cell/DateJournalNo.vue'
import { JournalItemType } from '#/TransactionsTypes'
import { useEntityStore } from '@/stores/modules/entity'
import journalTypeCell from '../Cell/journalTypeCell.vue'
import TextCell from '@/components/Base/Table/Cell/TextCell.vue'
import { useTransactionStore } from '@/stores/modules/transactions'
import CurrencyCell from '@/components/Reports/Cell/CurrencyCell.vue'
import { DetailsReconciliationLedgerType } from '#/ReconciliationTypes'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import AuxiliaryCode from '../../../components/Cell/AuxiliaryCodeCell.vue'
import DetailOverlay from '../../../../components/Journal/DetailOverlay.vue'
import AddJournalDialog from '../../../../components/Journal/AddJournalDialog.vue'
import JournalFilterOverlay from '../../../../components/Journal/FilterOverlay.vue'

const props = defineProps({
  tableData: {
    type: Object as () => DetailsReconciliationLedgerType,
    required: true
  },
  tableLoading: {
    type: Boolean,
    default: false
  },
  tableHeight: {
    type: String,
    required: true
  },
  params: {
    type: Object,
    required: true
  },
  tableDataSelected: {
    type: Array,
    required: true
  },
  activeTab: {
    type: String,
    required: true
  },
  selectedTotalAmount: {
    type: Number,
    required: true
  },
  disabledSelected: {
    type: String,
    default: ''
  },
  side: {
    type: String,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const tableListRef = ref()
const entityStore = useEntityStore()

const transactionStore = useTransactionStore()
// eslint-disable-next-line no-unused-vars
const reconciliationStore = useReconciliationStore()

const detailOverlayRef = useTemplateRef('detailOverlayRef')
const addJournalDialogRef = useTemplateRef('addJournalDialogRef')
const JournalFilterOverlayRef = useTemplateRef('JournalFilterOverlayRef')

const emit = defineEmits([
  'onClearSelected',
  'onChangePage',
  'onResetList',
  'onEditExplain',
  'onViewMatchDetail',
  'onChangePageSize',
  'onSelectionChange'
])

const auxiliaryAccountTypeId = ref('')
const currentData = ref<JournalItemType>({} as JournalItemType)

const entityId = computed(() => {
  return route.params?.entityId as string
})

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const journalTypeFilterList = computed(() => {
  return transactionStore.journalTypeFilterList('journal')
})

const onChangePage = (page: number) => {
  emit('onChangePage', page)
}

const onChangePageSize = (limit: number) => {
  emit('onChangePageSize', limit)
}

const onSelectionChange = (val: any) => {
  emit('onSelectionChange', val)
}

const setCellClassName = (data: any) => {
  let className = `elv-reconciliation-unmatched-table-row__cell`
  if (data?.row?.status === 'ADJUSTMENT') {
    className += ' is-unselectable'
  }
  return className
}

const onEditJournal = (row: JournalItemType) => {
  if (['MEMBER', ''].includes(currentEntityPermission.value?.role) && !currentEntityPermission.value?.journal?.update) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  addJournalDialogRef.value?.onCheckAddJournalDialog()
}

const onCellClicked = async (row: JournalItemType, column: any) => {
  if (column?.no === 7 || column?.no === 0) {
    return
  }
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.journal?.viewDetail
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  currentData.value = row
  detailOverlayRef.value?.onCheckDrawerStatus()
}

const selectable = (row: any) => {
  if (row?.status === 'ADJUSTMENT') return false
  if (props.disabledSelected === '') return true
  if (props.activeTab === 'PENDING') {
    return row.underlyingCurrency.symbol === props.disabledSelected
  }
  if (props.disabledSelected === 'MATCHED') {
    return !!row.reconciliationMatch?.matchNo
  }
  if (props.disabledSelected === 'EXPLAINED') {
    return !!row.reconciliationReason?.reconciliationReasonId
  }
  return true
}

const getAuxiliaryTypeList = async () => {
  try {
    const { data } = await LedgerApi.getAuxiliaryTypeList(entityId.value, { page: 1, limit: 1 })
    auxiliaryAccountTypeId.value = data.list?.[0]?.auxiliaryTypeId ?? ''
  } catch (error) {
    console.error(error)
  }
}
defineExpose({ tableListRef, JournalFilterOverlayRef })

onBeforeMount(() => {
  getAuxiliaryTypeList()
})
</script>

<style lang="scss">
.elv-reconciliation-unmatched-table-wrapper {
  width: 100%;
  height: calc(100% - 50px);
  position: relative;

  .elv-reconciliation-unmatched-table-row__cell.is-unselectable {
    .el-checkbox {
      display: none;
    }
  }
}
</style>
