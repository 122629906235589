<template>
  <div class="elv-reconciliation-unmatched-detail-page">
    <div class="elv-reconciliation-unmatched-tabBar" @click="onChangeTab($event)">
      <div
        class="elv-transaction-unmatched-tabBar-pane"
        :class="{ 'is-active': activeTab === 'PENDING' }"
        aria-controls="PENDING"
      >
        {{ t('reconciliation.pending') }} ({{ formatNumber(reconciliationTaskSummary.pendingCount) }})
      </div>
      <div
        class="elv-transaction-unmatched-tabBar-pane"
        :class="{ 'is-active': activeTab === 'PROCESSED' }"
        aria-controls="PROCESSED"
      >
        {{ t('reconciliation.processed') }} ({{ formatNumber(reconciliationTaskSummary.processedCount) }})
      </div>
      <ProgressCell
        :text="`${t('common.total')}: ${formatNumber(reconciliationTaskSummary.dataCount)}`"
        color="#1753EB"
        :percentage="percentage"
      />
    </div>
    <section v-loading="loading" class="elv-reconciliation-unmatched-container">
      <div class="elv-reconciliation-unmatched-header">
        <div class="elv-reconciliation-unmatched-header-column operating">
          <h3>{{ reconciliationTaskDetail?.dataSetNameA }}</h3>
          <div @click="onOpenFilterOverlay('LEFT', reconciliationTaskDetail?.dataSetTypeA)">
            <div
              v-if="!tableFilterData('LEFT', reconciliationTaskDetail?.dataSetTypeA)?.total"
              class="elv-reconciliation-unmatched-header-filter__add"
            >
              <SvgIcon name="add-line" width="14" height="14" />{{ t('common.addFilter') }}
            </div>
            <div v-else class="elv-reconciliation-unmatched-header-filter__count">
              {{ t('common.filter') }}:
              {{ formatNumber(tableFilterData('LEFT', reconciliationTaskDetail?.dataSetTypeA)?.total ?? 0) }}
            </div>
          </div>
        </div>
        <div class="elv-reconciliation-unmatched-header-column operating">
          <h3>{{ reconciliationTaskDetail?.dataSetNameB }}</h3>
          <div @click="onOpenFilterOverlay('RIGHT', reconciliationTaskDetail?.dataSetTypeB)">
            <div
              v-if="!tableFilterData('RIGHT', reconciliationTaskDetail?.dataSetTypeB)?.total"
              class="elv-reconciliation-unmatched-header-filter__add"
            >
              <SvgIcon name="add-line" width="14" height="14" />{{ t('common.addFilter') }}
            </div>
            <div v-else class="elv-reconciliation-unmatched-header-filter__count">
              {{ t('common.filter') }}:
              {{ formatNumber(tableFilterData('RIGHT', reconciliationTaskDetail?.dataSetTypeB)?.total ?? 0) }}
            </div>
          </div>
        </div>
      </div>
      <div class="elv-reconciliation-unmatched-content">
        <div class="elv-reconciliation-unmatched-left">
          <component
            :is="componentMap[reconciliationTaskDetail?.dataSetTypeA]"
            ref="leftSideTableRef"
            side="LEFT"
            :activeTab="activeTab"
            :params="leftSideParams"
            :tableHeight="tableHeight"
            :disabledSelected="disabledSelected"
            :tableDataSelected="leftSideTableDataSelected"
            :tableData="reconciliationStore.detailsReconciliationLeftSideData"
            :tableLoading="leftSideLoading"
            :selectedTotalAmount="selectedLeftSideTotalAmount"
            @onClearSelected="onClearSelected"
            @onResetList="getLeftSideTableDataList(true)"
            @onSelectionChange="(val) => onSelectionSideChange(val, 'LEFT')"
            @onEditExplain="(row: any) => onEditExplain(row, 'LEFT')"
            @onViewMatchDetail="(row: any) => onViewMatchDetail(row, 'LEFT')"
            @onChangePage="(page) => onChangeSidePage(page, 'LEFT')"
            @onChangePageSize="(limit) => onChangeSidePageSize(limit, 'LEFT')"
          />
        </div>
        <div class="elv-reconciliation-unmatched-line" />
        <div class="elv-reconciliation-unmatched-right">
          <component
            :is="componentMap[reconciliationTaskDetail?.dataSetTypeB]"
            ref="rightSideTableRef"
            side="RIGHT"
            :activeTab="activeTab"
            :params="rightSideParams"
            :tableHeight="tableHeight"
            :disabledSelected="disabledSelected"
            :tableDataSelected="rightSideTableDataSelected"
            :tableData="reconciliationStore.detailsReconciliationRightSideData"
            :tableLoading="rightSideLoading"
            :selectedTotalAmount="selectedRightSideTotalAmount"
            @onResetList="getRightSideTableDataList(true)"
            @onClearSelected="onClearSelected"
            @onSelectionChange="(val) => onSelectionSideChange(val, 'RIGHT')"
            @onEditExplain="(row: any) => onEditExplain(row, 'RIGHT')"
            @onViewMatchDetail="(row: any) => onViewMatchDetail(row, 'RIGHT')"
            @onChangePage="(page) => onChangeSidePage(page, 'RIGHT')"
            @onChangePageSize="(limit) => onChangeSidePageSize(limit, 'RIGHT')"
          />
        </div>
      </div>
    </section>

    <ExplainDialog
      ref="explainDialogRef"
      :dataType="
        leftSideTableDataSelected?.length
          ? reconciliationTaskDetail?.dataSetTypeA
          : reconciliationTaskDetail?.dataSetTypeB
      "
      :reconciliationDataIds="explainDataIds"
      :side="editExplainSide || (leftSideTableDataSelected?.length ? 'A' : 'B')"
      :model="activeTab === 'PENDING' ? 'add' : 'edit'"
      :currentData="currentExplainData"
      @onResetList="onResetList"
    />

    <DifferenceExplanationDialog
      ref="differenceExplanationDialogRef"
      :sideAReconciliationDataIds="leftSideTableDataSelected?.map((item: any) => item.reconciliationDataId)"
      :differenceAmount="differenceAmount"
      :sideBReconciliationDataIds="rightSideTableDataSelected?.map((item: any) => item.reconciliationDataId)"
      @onResetList="onResetList"
    />

    <ReconciliationTaskStatusChangeDialog
      v-if="showClearExplanationOrRemoveMatchDialog"
      v-model:show="showClearExplanationOrRemoveMatchDialog"
      v-model:loading="clearExplanationOrRemoveMatchLoading"
      v-model:agree="agreeRemovedData"
      :title="currentRemoveMatchType === 'MATCHED' ? t('report.unmatch') : t('reconciliation.clearExplanation')"
      @onConfirmChangeStatus="onConfirmSaveOperation"
      @onCloseDialog="onCloseConfirmDialog"
    >
    </ReconciliationTaskStatusChangeDialog>

    <MatchDetailDialog
      ref="matchDetailDialogRef"
      model="MATCHED"
      tableType="PROCESSED"
      :reconciliationMatchId="currentExplainData.reconciliationMatchId ?? ''"
      :currentData="currentExplainData"
      @onResetList="onResetList"
    />
  </div>
</template>

<script setup lang="ts">
import Big from 'big.js'
import { http } from '@/lib/http'
import { emitter } from '@/lib/mitt'
import { ElMessage } from 'element-plus'
import { union, isEmpty } from 'lodash-es'
import { formatNumber } from '@/lib/utils'
import { notification } from 'ant-design-vue'
import Toolbar from './components/Toolbar.vue'
import ReconciliationApi from '@/api/ReconciliationApi'
import ExplainDialog from './components/ExplainDialog.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import { useTransactionStore } from '@/stores/modules/transactions'
import ProgressCell from '@/components/Base/Table/Cell/ProgressCell.vue'
import { useReconciliationStore } from '@/stores/modules/reconciliation'
import TransactionTable from './components/SideTable/TransactionTable.vue'
import AccountingLedgerTable from './components/SideTable/AccountingLedgerTable.vue'
import DifferenceExplanationDialog from './components/DifferenceExplanationDialog.vue'
import ReconciliationTaskStatusChangeDialog from '../components/ReconciliationTaskStatusChangeDialog.vue'
import MatchDetailDialog from '@/pages/Financials/Project/components/ReconciliationMatched/MatchDetailDialog.vue'

const { t } = useI18n()
const route = useRoute()
const accountStore = useAccountStore()
const transactionStore = useTransactionStore()
const reconciliationStore = useReconciliationStore()
const { reconciliationTaskSummary, reconciliationTaskDetail } = storeToRefs(reconciliationStore)

const leftSideParams = ref({
  page: 1,
  limit: 20
})
const rightSideParams = ref({
  page: 1,
  limit: 20
})
const loading = ref(false)
const activeTab = ref('PENDING')
const matchLoading = ref(false)
const isCancelRequest = ref(false)
const leftSideLoading = ref(false)
const rightSideLoading = ref(false)
const agreeRemovedData = ref(false)
const clearExplanationOrRemoveMatchLoading = ref(false)
const showClearExplanationOrRemoveMatchDialog = ref(false)
const editExplainSide = ref('')
const confirmType = ref('')
const currentExplainData: any = ref({})
const leftSideTableDataSelected: any = ref([])
const rightSideTableDataSelected: any = ref([])
const leftSideTableRef = ref()
const rightSideTableRef = ref()

const explainDialogRef = useTemplateRef('explainDialogRef')
const matchDetailDialogRef = useTemplateRef('matchDetailDialogRef')
const differenceExplanationDialogRef = useTemplateRef('differenceExplanationDialogRef')

const componentMap = {
  TRANSACTION: TransactionTable,
  LEDGER: AccountingLedgerTable
}

const tableHeight = computed(() => {
  return `calc(100vh - 311px)`
})

const reconciliationTaskId = computed(() => {
  return route.params?.reconciliationTaskId as string
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const percentage = computed(() => {
  if (reconciliationTaskSummary.value?.dataCount === 0) return 0
  return (
    Big(reconciliationTaskSummary.value?.processedCount)
      .div(reconciliationTaskSummary.value?.dataCount)
      .times(100)
      .toNumber() ?? 0
  )
})

const currentRemoveMatchType = computed(() => {
  if (confirmType.value === 'MATCHED') {
    return 'MATCHED'
  }
  return leftSideTableDataSelected.value?.[0]?.reconciliationMatch?.matchNo ||
    rightSideTableDataSelected.value?.[0]?.reconciliationMatch?.matchNo
    ? 'MATCHED'
    : 'EXPLANATION'
})

/**
 * @description: 解释数据ID
 */
const explainDataIds = computed(() => {
  if (!leftSideTableDataSelected.value?.length && !rightSideTableDataSelected.value?.length) {
    return []
  }
  return leftSideTableDataSelected.value?.length
    ? leftSideTableDataSelected.value?.map((item: any) => item.reconciliationDataId)
    : rightSideTableDataSelected.value?.map((item: any) => item.reconciliationDataId)
})

const selectedReconciliationList = computed(() => {
  const leftData = leftSideTableDataSelected.value || []
  const rightData = rightSideTableDataSelected.value || []

  return [...leftData, ...rightData]
})

const selectedReconciliationDataIds = computed(() => {
  const list = selectedReconciliationList.value.map((item: any) => item.reconciliationDataId)

  return union(list)
})

/**
 * @description: 选择匹配数据IDs（去重）
 */
const selectedReconciliationMatchIds = computed(() => {
  const leftData = leftSideTableDataSelected.value || []
  const rightData = rightSideTableDataSelected.value || []

  const leftIds = leftData.map((item: any) => item.reconciliationMatchId)
  const rightIds = rightData.map((item: any) => item.reconciliationMatchId)

  return Array.from(new Set([...leftIds, ...rightIds]))
})

/**
 * @description: 禁用按钮
 */
const disabledSelected = computed(() => {
  if (leftSideTableDataSelected.value.length || rightSideTableDataSelected.value.length) {
    if (activeTab.value === 'PENDING') {
      return (
        leftSideTableDataSelected.value?.[0]?.currency ??
        rightSideTableDataSelected.value?.[0]?.underlyingCurrency?.symbol
      )
    }
    return leftSideTableDataSelected.value?.[0]?.reconciliationMatch?.matchNo ||
      rightSideTableDataSelected.value?.[0]?.reconciliationMatch?.matchNo
      ? 'MATCHED'
      : 'EXPLANATION'
  }
  return ''
})

const tableFilterData: any = computed(() => {
  return (side: string, dataSetType: string) => {
    if (dataSetType === 'TRANSACTION') {
      const filterSaveData = reconciliationStore.transactionFilterList.find((item: any) => {
        return item.type === `RECONCILIATION_${activeTab.value}_${side}`
      })
      if (filterSaveData?.list) {
        return (
          filterSaveData.list.find((item: any) => {
            return item.entityId === entityId.value && item?.reconciliationTaskId === reconciliationTaskId.value
          }) ?? {}
        )
      }
    }
    if (dataSetType === 'LEDGER') {
      const filterSaveData =
        reconciliationStore.journalFilterList.find((item: any) => {
          return (
            item.type === `RECONCILIATION_${activeTab.value}_${side}` &&
            item.entityId === entityId.value &&
            item?.reconciliationTaskId === reconciliationTaskId.value
          )
        }) ?? {}
      return filterSaveData
    }
    return {}
  }
})

/**
 * @description: 左侧选中的总金额
 */
const selectedLeftSideTotalAmount = computed(() => {
  const selectedValue = leftSideTableDataSelected.value.reduce((acc: Big, cur: any) => {
    return acc.plus(cur.amount !== null && cur?.amount !== '0' ? cur?.amount : 0)
  }, Big(0))
  return selectedValue.toNumber()
})

/**
 * @description: 右侧选中的总金额
 */
const selectedRightSideTotalAmount = computed(() => {
  const selectedValue = rightSideTableDataSelected.value.reduce((acc: Big, cur: any) => {
    return acc.plus(cur.amount !== null && cur?.amount !== '0' ? cur?.amount : 0)
  }, Big(0))
  return selectedValue.toNumber()
})

/**
 * @description: 差异金额
 */
const differenceAmount = computed(() => {
  const differenceValue = Big(selectedLeftSideTotalAmount.value).minus(selectedRightSideTotalAmount.value)
  return differenceValue.toNumber()
})

const formatterTableFilterParams = (side: string, dataSetType: string) => {
  let bodyData: Record<string, unknown> = {}
  const filterData = tableFilterData.value(side, dataSetType)?.data ?? {}
  if (dataSetType === 'TRANSACTION' && !isEmpty(filterData)) {
    bodyData = {
      ...filterData
    }
    if (filterData?.currency?.length) {
      bodyData.currency = filterData?.currency.map((item: string) => {
        return item.split('__')[1] ?? item
      })
    }
    if (filterData?.journalTypeIds?.length) {
      bodyData.journalTypeIds = filterData?.journalTypeIds.filter((item: string) => {
        return (
          item === '0' ||
          transactionStore.journalTypeList.find((journalType: any) => {
            return journalType.journalTypeId === item
          })
        )
      })
    }
  }
  if (dataSetType === 'LEDGER' && !isEmpty(filterData)) {
    bodyData = {
      ...filterData
    }
    if (!isEmpty(filterData)) {
      bodyData = {
        ...filterData
      }
      if (filterData?.source) {
        if (filterData?.source !== 'MANUAL') {
          const sourceData = accountStore.allSourceList.list.find((item: any) => {
            return (
              item?.sourceId === filterData?.source ||
              item?.externalJournalSourceId === filterData?.source ||
              item?.businessDataSourceId === filterData?.source
            )
          })
          if (isEmpty(sourceData)) {
            delete bodyData.sourceId
            delete bodyData.businessDataSourceId
            delete bodyData.externalJournalSourceId
          }
        }
        delete bodyData.source
      }
      if (filterData?.currency?.length) {
        bodyData.currency = filterData?.currency.map((item: string) => {
          return item.split('__')[1] ?? item
        })
      }
      if (filterData?.journalTypeIds?.length) {
        bodyData.journalTypeIds = filterData?.journalTypeIds.filter((item: string) => {
          return transactionStore.journalTypeList.find((journalType: any) => {
            return journalType.journalTypeId === item
          })
        })
      }
    }
  }
  return bodyData
}

/**
 * @description: 获取左侧表格数据
 * @param {boolean} isResetCount 是否重置统计
 */
const getLeftSideTableDataList = async (isResetCount: boolean = false) => {
  try {
    leftSideLoading.value = true
    const requestList: any = [
      reconciliationStore.fetchReconciliationSideTableList(entityId.value, reconciliationTaskId.value, {
        type: activeTab.value,
        dataSetType: reconciliationTaskDetail.value?.dataSetTypeA,
        params: { ...leftSideParams.value, side: 'A' },
        bodyData: formatterTableFilterParams('LEFT', reconciliationTaskDetail.value?.dataSetTypeA)
      })
    ]
    if (isResetCount) {
      requestList.push(reconciliationStore.fetchReconciliationTaskSummary(entityId.value, reconciliationTaskId.value))
    }
    await Promise.allSettled(requestList)
    leftSideTableRef.value?.tableListRef?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    leftSideLoading.value = false
  }
}

/**
 * @description: 获取右侧表格数据
 * @param {boolean} isResetCount 是否重置统计
 */
const getRightSideTableDataList = async (isResetCount: boolean = false) => {
  try {
    rightSideLoading.value = true
    const requestList: any = [
      reconciliationStore.fetchReconciliationSideTableList(entityId.value, reconciliationTaskId.value, {
        type: activeTab.value,
        dataSetType: reconciliationTaskDetail.value?.dataSetTypeB,
        params: { ...rightSideParams.value, side: 'B' },
        bodyData: formatterTableFilterParams('RIGHT', reconciliationTaskDetail.value?.dataSetTypeB)
      })
    ]
    if (isResetCount) {
      requestList.push(reconciliationStore.fetchReconciliationTaskSummary(entityId.value, reconciliationTaskId.value))
    }
    await Promise.allSettled(requestList)
    rightSideTableRef.value?.tableListRef?.setScrollTop(0)
  } catch (error) {
    console.log(error)
  } finally {
    rightSideLoading.value = false
  }
}

/**
 * @description: 打开过滤器
 * @param {string} side 侧边 LEFT/RIGHT
 * @param {string} dataSetType 数据集类型
 */
const onOpenFilterOverlay = (side: string, dataSetType: string) => {
  switch (dataSetType) {
    case 'TRANSACTION':
      if (side === 'LEFT') {
        leftSideTableRef.value?.transferFilterOverlayRef?.onCheckDrawerStatus()
      } else {
        rightSideTableRef.value?.transferFilterOverlayRef?.onCheckDrawerStatus()
      }
      break
    case 'LEDGER':
      if (side === 'LEFT') {
        leftSideTableRef.value?.JournalFilterOverlayRef?.onCheckDrawerStatus()
      } else {
        rightSideTableRef.value?.JournalFilterOverlayRef?.onCheckDrawerStatus()
      }
      break
    default:
      break
  }
}

/**
 * @description: 批量解释
 */
const onBatchExplain = () => {
  editExplainSide.value = ''
  currentExplainData.value = {}
  explainDialogRef.value?.onCheckExplainDialog()
}

/**
 * @description: 批量打标签
 */
const onBatchCategorize = () => {
  if (reconciliationTaskDetail.value?.dataSetTypeA === 'TRANSACTION') {
    leftSideTableRef.value?.createJournalDialogRef?.onCheckCreateJournalDialog()
  } else if (reconciliationTaskDetail.value?.dataSetTypeB === 'TRANSACTION') {
    rightSideTableRef.value?.createJournalDialogRef?.onCheckCreateJournalDialog()
  }
}

/**
 * @description: 清空选中
 */
const onClearSelected = () => {
  notification.close('elv-reconciliation-details-toolbar')
  leftSideTableDataSelected.value = []
  rightSideTableDataSelected.value = []
  leftSideTableRef.value?.tableListRef?.clearSelection()
  rightSideTableRef.value?.tableListRef?.clearSelection()
}

/**
 * 对账明细列表数据初始化
 */
const dataListDataInit = async () => {
  loading.value = true
  return new Promise<any>((resolve, reject) => {
    reconciliationStore
      .detailsReconciliationDataListDataInit(entityId.value, reconciliationTaskId.value, activeTab.value, {
        leftSideParams: { ...leftSideParams.value, side: 'A' },
        rightSideParams: { ...rightSideParams.value, side: 'B' },
        dataSetTypeA: reconciliationTaskDetail.value?.dataSetTypeA,
        dataSetTypeB: reconciliationTaskDetail.value?.dataSetTypeB,
        leftSideBodyData: formatterTableFilterParams('LEFT', reconciliationTaskDetail.value?.dataSetTypeA),
        rightSideBodyData: formatterTableFilterParams('RIGHT', reconciliationTaskDetail.value?.dataSetTypeB)
      })
      .then((res) => {
        resolve(res)
      })
      .catch((error) => {
        reject(error)
      })
      .finally(() => {
        reconciliationStore.detailsReconciliationRequestData = []
        if (!isCancelRequest.value) {
          loading.value = false
        }
      })
  })
}

/**
 * @description: 清空左右侧表格数据
 */
const clearSideTableData = () => {
  leftSideParams.value = {
    page: 1,
    limit: 20
  }
  rightSideParams.value = {
    page: 1,
    limit: 20
  }
  reconciliationStore.detailsReconciliationLeftSideData.list = []
  reconciliationStore.detailsReconciliationRightSideData.list = []
  onClearSelected()
}

/**
 * @description: 重置列表
 */
const onResetList = () => {
  clearSideTableData()
  loading.value = true
  reconciliationStore.fetchReconciliationTaskSummary(entityId.value, reconciliationTaskId.value)
  dataListDataInit()
}

const onCloseConfirmDialog = () => {
  confirmType.value = ''
  showClearExplanationOrRemoveMatchDialog.value = false
}

/**
 * @description: 确认清空解释或移除匹配
 */
const onConfirmSaveOperation = async () => {
  try {
    clearExplanationOrRemoveMatchLoading.value = true
    const data: any = {
      isRemoveReconciliationAdjustment: agreeRemovedData.value
    }
    if (currentRemoveMatchType.value === 'MATCHED') {
      data.reconciliationMatchIds =
        confirmType.value === 'MATCHED' && currentRemoveMatchType.value === 'MATCHED'
          ? [currentExplainData.value.reconciliationMatchId]
          : selectedReconciliationMatchIds.value
      await ReconciliationApi.manualReconciliationUnmatched(entityId.value, reconciliationTaskId.value, data)
      confirmType.value = ''
    } else {
      data.reconciliationDataIds = selectedReconciliationDataIds.value
      await ReconciliationApi.clearReconciliationTaskReason(entityId.value, reconciliationTaskId.value, data)
    }
    showClearExplanationOrRemoveMatchDialog.value = false
    onClearSelected()
    onResetList()
    ElMessage.success(t('message.success'))
  } catch (error: any) {
    ElMessage.error(error?.message)
  } finally {
    clearExplanationOrRemoveMatchLoading.value = false
  }
}

/**
 * @description: 匹配对账
 */
const onMatchReconciliation = async () => {
  if (!leftSideTableDataSelected.value.length || !rightSideTableDataSelected.value.length) return
  try {
    matchLoading.value = true
    await ReconciliationApi.manualReconciliationMatch(entityId.value, reconciliationTaskId.value, {
      sideAReconciliationDataIds: leftSideTableDataSelected.value.map((item: any) => {
        return { reconciliationDataId: item.reconciliationDataId }
      }),
      sideBReconciliationDataIds: rightSideTableDataSelected.value.map((item: any) => {
        return { reconciliationDataId: item.reconciliationDataId }
      })
    })

    ElMessage.success(t('message.success'))
    onResetList()
  } catch (error: any) {
    matchLoading.value = false
    loading.value = false
    console.log(error)
    ElMessage.error(error.message)
  }
}

/**
 * @description: 批量手动对账
 */
const onBatchReconciliationMatch = () => {
  if (differenceAmount.value !== 0) {
    differenceExplanationDialogRef.value?.onCheckExplainDialog()
  } else {
    onMatchReconciliation()
  }
}

/**
 * @description: 批量清空解释或移除匹配
 */
const onBatchClearExplainOrRemoveMatch = async () => {
  if (!leftSideTableDataSelected.value.length && !rightSideTableDataSelected.value.length) return
  const hasMatchedWithoutReason = selectedReconciliationList.value.every((item: any) => {
    return (
      (!item?.reconciliationReason?.reconciliationReasonId ||
        item?.reconciliationMatch?.reconciliationRuleId !== '0') &&
      item.reconciliationMatch?.reconciliationMatchId
    )
  })

  if (hasMatchedWithoutReason) {
    try {
      matchLoading.value = true
      await ReconciliationApi.manualReconciliationUnmatched(entityId.value, reconciliationTaskId.value, {
        isRemoveReconciliationAdjustment: false,
        reconciliationMatchIds: selectedReconciliationMatchIds.value
      })
      onClearSelected()
      ElMessage.success(t('message.success'))
      onResetList()
    } catch (error: any) {
      console.log(error)
      ElMessage.error(error.message)
    } finally {
      matchLoading.value = false
    }
    return
  }

  showClearExplanationOrRemoveMatchDialog.value = true
}

/**
 * @description: 选择数据
 * @param {*} val 选中数据
 * @param {string} side 左右侧
 */
const onSelectionSideChange = (val: any, side: string) => {
  if (side === 'LEFT') {
    leftSideTableDataSelected.value = val
  } else {
    rightSideTableDataSelected.value = val
  }
  if (leftSideTableDataSelected.value?.length || rightSideTableDataSelected.value?.length) {
    notification.open({
      key: 'elv-reconciliation-details-toolbar',
      message: '',
      description: () =>
        h(Toolbar, {
          activeTab: activeTab.value,
          matchLoading: matchLoading.value,
          selectedTotal:
            (leftSideTableDataSelected.value?.length || 0) + (rightSideTableDataSelected.value?.length || 0),
          differenceAmount: differenceAmount.value,
          leftSideTableDataSelected: leftSideTableDataSelected.value,
          rightSideTableDataSelected: rightSideTableDataSelected.value,
          onClearSelected,
          onBatchReconciliationMatch,
          onBatchClearExplainOrRemoveMatch,
          onBatchCategorize,
          onBatchExplain
        }),
      class: 'elv-reconciliation-details-toolbar elv-table-toolbar',
      duration: 0,
      placement: 'bottom'
    })
  } else {
    notification.close('elv-reconciliation-details-toolbar')
  }
}

/**
 * @description: 切换页码
 * @param {number} page 页码
 * @param {string} side 左右侧
 */
const onChangeSidePage = (page: number, side: string) => {
  if (side === 'LEFT') {
    leftSideParams.value.page = page
    getLeftSideTableDataList()
  } else {
    rightSideParams.value.page = page
    getRightSideTableDataList()
  }
}

/**
 * @description: 切换分页
 * @param {number} limit 分页大小
 * @param {string} side 左右侧
 */
const onChangeSidePageSize = (limit: number, side: string) => {
  if (side === 'LEFT') {
    leftSideParams.value.limit = limit
    leftSideParams.value.page = 1
    getLeftSideTableDataList()
  } else {
    rightSideParams.value.limit = limit
    rightSideParams.value.page = 1
    getRightSideTableDataList()
  }
}

/**
 * @description: 切换tab
 * @param {Event} e
 */
const onChangeTab = async (e: Event) => {
  const { target } = e
  const tab = (target as HTMLElement)?.getAttribute('aria-controls')
  if (tab) {
    activeTab.value = tab
    if (reconciliationStore.detailsReconciliationRequestData.length) {
      http?.cancelRequest(reconciliationStore.detailsReconciliationRequestData)
      isCancelRequest.value = true
      reconciliationStore.detailsReconciliationRequestData = []
    }
    loading.value = true
    clearSideTableData()
    const responseList = await dataListDataInit()
    const hasCancel = responseList.some((item: any) => {
      return item?.status === 'rejected' && item?.reason?.message === 'canceled'
    })
    if (hasCancel) {
      return
    }
    isCancelRequest.value = false
    loading.value = false
  }
}

/**
 * @description: 编辑解释
 * @param {*} row
 * @param {string} side
 */
const onEditExplain = (row: any, side: string) => {
  currentExplainData.value = row
  editExplainSide.value = side === 'LEFT' ? 'A' : 'B'
  explainDialogRef.value?.onCheckExplainDialog()
}

/**
 * @description: 查看匹配详情
 * @param {*} row
 * @param {string} side
 */
const onViewMatchDetail = (row: any, side: string) => {
  currentExplainData.value = row
  editExplainSide.value = side === 'LEFT' ? 'A' : 'B'
  matchDetailDialogRef.value?.onCheckMatchDetailDialog()
}

emitter.on('reconciliationDetailsDataInit', dataListDataInit)

watch(
  () => route,
  async (newValue) => {
    if (newValue.name === 'entity-reconciliation-details') {
      loading.value = true
      activeTab.value = route?.query?.type === 'PROCESSED' ? 'PROCESSED' : 'PENDING'
      dataListDataInit()
      reconciliationStore.detailsReconciliationFilterDataInit(entityId.value, reconciliationTaskId.value)
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  emitter.off('reconciliationDetailsDataInit', dataListDataInit)
  editExplainSide.value = ''
  currentExplainData.value = {}
  reconciliationStore.detailsReconciliationLeftSideData = { totalCount: 0, filteredCount: 0, list: [] }
  reconciliationStore.detailsReconciliationRightSideData = { totalCount: 0, filteredCount: 0, list: [] }
  reconciliationStore.reconciliationTaskSummary = {
    pendingCount: 0,
    processedCount: 0,
    dataCount: 0
  }
  reconciliationStore.detailsReconciliationRequestData = []
  isCancelRequest.value = false
  onClearSelected()
  notification.destroy()
})
</script>

<style lang="scss" scoped>
.elv-reconciliation-unmatched-detail-page {
  width: 100%;
  height: calc(100% - 128px);
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-reconciliation-unmatched-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    position: relative;
  }
}

.elv-reconciliation-unmatched-tabBar {
  display: flex;
  align-items: center;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  margin-left: 14px;
  margin-top: 16px;

  .elv-transaction-unmatched-tabBar-pane {
    display: flex;
    height: 27px;
    padding: 0px 16px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid #e5edff;
    background: #fff;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    transition: all 0.1s;
    white-space: nowrap;

    &:not(.is-active):hover {
      border: 1px solid #5e85eb;

      .elv-transaction-rule-tabBar-pane__arrow {
        fill: #5e85eb;
      }
    }

    &.is-active {
      background: #1753eb;
      color: #fff;
      border: 1px solid #1753eb;

      .elv-transaction-rule-tabBar-pane__arrow {
        fill: #fff;
      }
    }
  }

  :deep(.elv-table-base-progress) {
    flex-direction: row;
    align-items: center;
    margin-left: 16px;

    .elv-table-base-progress-text {
      margin-top: 0;
      margin-left: 8px;
      color: #4e515a;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.elv-reconciliation-unmatched-header {
  display: flex;
  padding-top: 16px;
  padding-bottom: 8px;
  box-sizing: content-box;

  .elv-reconciliation-unmatched-header-column {
    // flex: 1;
    // flex-grow: 1;
    display: flex;
    align-items: center;
    width: 50%;
    box-sizing: border-box;
    position: relative;

    &:first-of-type {
      padding-left: 20px !important;
      padding-right: 11px !important;
    }

    h3 {
      font-family: 'Plus Jakarta Sans';
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      color: #1e2024;
      font-weight: 600;
      max-width: calc(100% - 90px);
    }

    &.operating {
      justify-content: space-between;
      padding-right: 26px;
      padding-left: 6px;

      .elv-reconciliation-unmatched-header-filter__add {
        display: flex;
        align-items: center;
        color: #1e2024;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        svg {
          fill: #939ba2;
          transition: fill 0.1s;
          margin-right: 8px;
        }

        &:hover {
          cursor: pointer;

          svg {
            fill: #1e2024;
          }
        }
      }

      .elv-reconciliation-unmatched-header-filter__count {
        display: flex;
        padding: 0px 11px;
        height: 19px;
        box-sizing: border-box;
        align-items: center;
        border-radius: 10px;
        background: #5e85eb;
        color: #fff;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
  // > div {
  //   padding-left: 20px;
  // }
}

.elv-reconciliation-unmatched-content {
  width: 100%;
  flex: 1;
  min-height: 0;
  display: flex;
  align-items: flex-start;
  position: relative;

  .elv-reconciliation-unmatched-left,
  .elv-reconciliation-unmatched-right {
    height: 100%;
    width: 50% !important;
    position: relative;
  }

  .elv-reconciliation-unmatched-left {
    display: flex;
  }

  .elv-reconciliation-unmatched-line {
    width: 6px;
    min-width: 6px;
    box-sizing: border-box;
    height: calc(100% - 50px);
    border: 1px solid #ced7e0;
    background: #eef4fb;
  }
}
</style>
<style lang="scss">
.elv-reconciliation-unmatched-content {
  .el-table {
    // 宽度为外层容器的宽度
    width: 100%;

    .el-table__empty-block {
      width: 100% !important;
    }
  }
}

.elv-reconciliation-unmatched-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  --el-table-header-border: 1px solid #e4e7eb;
  --el-fill-color-lighter: #f9fafb;
  // width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      padding-bottom: 42px;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  &.is-scrolling-middle,
  &.is-scrolling-right {
    .elv-reconciliation-unmatched-table-header
      .el-table-fixed-column--left.elv-reconciliation-unmatched-table-row__index.is-last-column.el-table__cell {
      border-right: none !important;
    }
  }

  &.is-scrolling-none {
    .el-table__body-wrapper {
      .elv-reconciliation-unmatched-table-row .elv-reconciliation-unmatched-table-row__currency {
        border-right: 0px !important;
      }

      .is-right.el-table-fixed-column--right.is-first-column {
        border-left: 1px solid #edf0f3 !important;
      }
    }

    .el-table__header-wrapper {
      .elv-reconciliation-unmatched-table-header .elv-reconciliation-unmatched-table-row__currency {
        border-right: 0px !important;
      }

      .is-right.el-table-fixed-column--right.is-first-column {
        border-left: 1px solid #e4e7eb !important;
      }
    }
  }

  .elv-reconciliation-unmatched-table-header {
    background: #eef4fb;

    .elv-reconciliation-unmatched-table-header__cell {
      background: #eef4fb !important;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;
      padding: 0px;

      &.el-table-fixed-column--left {
        background: #eef4fb !important;
        position: relative !important;
        border-right: var(--el-table-header-border) !important;
        left: 0px !important;

        &.elv-reconciliation-unmatched-table-row__index {
          position: sticky !important;
          z-index: 10;

          .cell {
            display: none;
          }
        }
      }

      &.elv-reconciliation-unmatched-table-row__amount.is-right {
        position: sticky !important;
        z-index: 10;
        right: 0;
      }

      &.elv-reconciliation-unmatched-table-row__currency.el-table-fixed-column--left.is-last-column::before {
        box-shadow: none !important;
      }

      &:last-child {
        border-right: 0px;
      }

      &.is-right .cell {
        justify-content: flex-end;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  .elv-reconciliation-unmatched-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;

      .elv-journals-table-cell__account p {
        font-weight: 400;
        min-width: 135px;
        max-width: 100%;
        width: unset;
      }

      .elv-journals-table-cell_journalType .elv-journals-table-cell_journal-entry {
        color: #6b7177;
        font-family: 'Barlow';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0;
      }
    }

    &.elv-reconciliation-unmatched-table-row__amount .elv-base-cell-text {
      white-space: nowrap;
      overflow: hidden;
    }

    &.elv-reconciliation-unmatched-table-row__status {
      .cell {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: 100%;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        height: 18px;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border-radius: 40px;
      }

      .is-explained {
        color: #1753eb;
        background: #eff4ff;
        cursor: pointer;
      }

      .is-matched {
        color: #41780c;
        background: #f1f8ea;
        cursor: pointer;
      }

      p {
        color: #6b7177;
        font-family: 'Barlow';
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
      }
    }

    &.is-right .cell {
      text-align: right;
    }

    &.is-center .cell {
      text-align: center;
    }

    .elv-reconciliation-unmatched-table-row__cell-activity {
      font-weight: 500;
    }

    .elv-reconciliation-unmatched-table-row__cell-type {
      display: flex;
      align-items: center;
      font-size: 11px;
      font-weight: 400;
      color: #636b75;
      white-space: nowrap;

      svg {
        margin-right: 8px;
      }
    }

    &.el-table-column--selection {
      .cell {
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .el-table__footer-wrapper {
    height: 42px;

    .el-table__footer {
      height: 100%;

      .cell.elv-reconciliation-unmatched-table-row__total {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .cell.elv-reconciliation-unmatched-table-row__total-amount {
        color: #0e0f11;
        font-family: 'Barlow';
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}

.elv-transactions-pagination__sizes-popper.el-popper {
  transform: translateY(10.5px);
}
</style>
